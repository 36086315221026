import { render, staticRenderFns } from "./illustrate.vue?vue&type=template&id=94280c56&scoped=true"
import script from "./illustrate.vue?vue&type=script&lang=js"
export * from "./illustrate.vue?vue&type=script&lang=js"
import style0 from "./illustrate.vue?vue&type=style&index=0&id=94280c56&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94280c56",
  null
  
)

export default component.exports