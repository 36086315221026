<template>
  <div id="app" class="app">
    <GlobalHeader />
    <main id="main">
      <div id="container">
        <router-view v-if="!$route.meta.iskeep"></router-view>
        <keep-alive>
          <router-view v-if="$route.meta.iskeep"></router-view>
        </keep-alive>
      </div>
      <GlobalFooter id="globalFooter" />
    </main>
    <bottomUser v-if="$store.state.mode == 'mobile'" />
    <illustrate :visible="isillustrate" />
  </div>
</template>

<script>
import { getToken } from '@/api/cookie';
import { setRem } from '@/utils/rem';
import GlobalHeader from '@/components/GlobalHeader.vue';
import GlobalFooter from '@/components/GlobalFooter.vue';
import bottomUser from '@/components/bottomUser.vue';

export default {
  data() {
    return {
      isillustrate: false,
    };
  },
  components: {
    GlobalHeader,
    GlobalFooter,
    bottomUser,
  },
  created() {
    setRem();
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.commit('playAudio');
    });

    this.getToken();
    this.$bus.$on('is_illustrate', data => {
      this.isillustrate = data;
    });
  },
  methods: {
    getToken() {
      if (getToken()) {
        this.$store.commit('LOGIN_IS_SHOW', false);
      } else {
        let timer = setTimeout(() => {
          this.$router.push('/login');
          this.$store.commit('LOGIN_IS_SHOW', true);
          clearTimeout(timer);
        }, 500);
      }
    },
  },
};
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
  .flex-column;
  #main {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    background: @bg1;
    .flex-column;
    #container {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
