<template>
  <div :class="['common_price', size || 'small', className]">
    <img v-if="type == 2" src="../../assets/svgs/jifen.svg" alt="" />
    <img v-else src="../../assets/svgs/jinbi.svg" alt="" />
    <span v-if="price != undefined" :class="type == 2 ? 'jifen' : 'jinbi'">{{ price }}</span>
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: Number,
    },
    price: {
      type: [String, Number],
      defalut: '',
    },
    size: {
      type: String,
      defalut: '',
    },
    className: {
      type: String,
      defalut: '',
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.common_price {
  .flex-a-center;
  & > img {
    margin: 0;
    display: inline-block;
  }
  & > span {
    padding-left: 2px;
    white-space: nowrap;
  }
  .jinbi {
    color: @jinbi;
  }
  .jifen {
    color: @jifen;
  }
}
.small {
  & > img {
    width: 14px;
  }
  & > span {
    font-size: 14px;
  }
}
.middle {
  & > img {
    width: 16px;
  }
  & > span {
    font-size: 16px;
  }
}
.large {
  & > img {
    width: 18px;
  }
  & > span {
    font-size: 18px;
  }
}
</style>
